<template>
  <MPopover
    open
    class-name="header-bar__geo-popover"
    position="bottom-start"
    ignore-close-on-desktop
    with-close
    @open="getCityToRedirect"
    @close="closeCityPopover"
  >
    <template #content>
      <AImage
        class="logo"
        provider="public"
        src="/assets/images/logo.svg"
        width="200"
        height="36"
      />

      <div class="description">
        {{ $t("molecules.header.your-city-desc") }}
      </div>
      <div class="buttons">
        <AButton
          v-if="cityToRedirect"
          size="xs"
          @click="changeCity(cityToRedirect)"
        >
          {{ cityToRedirect.name }}
        </AButton>
        <AButton
          :variant="cityToRedirect ? 'secondary' : undefined"
          size="xs"
          @click="confirmCurrentCity"
        >
          {{ currentRegionName }}
        </AButton>
        <AActionButton
          size="sm"
          underline
          @click="openCityModal"
        >
          {{ $t('molecules.header.your-city-change') }}
        </AActionButton>
      </div>
    </template>
  </MPopover>
</template>

<script lang="ts" setup>
import { defineComponent, ref } from 'vue'
import { useRedirect, useHydrationData, useMainDomainCookie, useHydrationRef, isNumber } from '#imports'
import { useConfig } from '@/stores/config'
import { useMainStore } from '@/stores'

import AActionButton from '@/components/atoms/ActionButton/AActionButton.vue'
import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'

defineComponent({ name: 'MHeaderTop' })

const allRegions = useMainDomainCookie('all-regions')
const { getRegionsByCountryCode, currentRegion, currentRegionName, mainRegionId } = useConfig()
const { addToRedirectCookie, changeCity } = useRedirect()
const { openModalCity } = useMainStore()

const showCityAlert = useHydrationRef('city-alert', false)

const { data: hasCities } = await useHydrationData('cities-has-any', async () => {
  const cities = await getRegionsByCountryCode(currentRegion.value.country?.code ?? '')

  return cities.length > 1
})

const cityToRedirect = ref()

const getCityToRedirect = async () => {
  const cities = await getRegionsByCountryCode(currentRegion.value.country?.code ?? '')
  const cityAlertRegionId = useHydrationRef('city-alert-region-id')

  if (isNumber(cityAlertRegionId.value) && cityAlertRegionId.value !== currentRegion.value.id) {
    const cityData = (cities || []).find(({ id }) => id === cityAlertRegionId.value)

    if (cityData) {
      cityToRedirect.value = {
        id: cityAlertRegionId.value,
        name: cityData.name || '',
        subdomain: cityData.subdomain || '',
        allRegions: allRegions.value && cityAlertRegionId.value === mainRegionId.value.ru
      }
    }
  }
}

const confirmCurrentCity = (): void => {
  addToRedirectCookie(currentRegion.value.id)
  closeCityPopover()
}

const closeCityPopover = (): void => {
  showCityAlert.value = false
}

const openCityModal = (): void => {
  if (hasCities.value) {
    closeCityPopover()
    openModalCity()
  }
}
</script>
