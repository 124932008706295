import type { ComputedRef } from 'vue'
import { ref, computed } from 'vue'
import { useConfig } from '@/stores/config'

type UseNotification = {
  isNotificationShow: ComputedRef<boolean>
  notification: ComputedRef<string>
  getNotification: () => void
}

export const useNotification = (): UseNotification => {
  const isNotificationShow = ref(false)
  const notification = ref('')
  const { getRegionAnnouncements } = useConfig()

  async function getNotifications () {
    try {
      const regionAnnouncements = await getRegionAnnouncements()

      if (!(Array.isArray(regionAnnouncements) && regionAnnouncements.length)) {
        isNotificationShow.value = false
        return
      }

      return regionAnnouncements
    } catch (err) {
      return []
    }
  }

  const getNotification = async (): Promise<void> => {
    const notifications = await getNotifications()
    if (!notifications) return
    notification.value = notifications[0]

    isNotificationShow.value = notification.value.length !== 0
  }

  return {
    isNotificationShow: computed(() => isNotificationShow.value),
    notification: computed(() => notification.value),
    getNotification
  }
}
