<template>
  <HeaderBase>
    <template #left>
      <AIconButton
        icon-name="arrow-line"
        class="m-header-center__contactbar-action m-header-center__burger"
        @click="goBack"
      />
    </template>

    <template #right>
      <AIconButton
        class="favorite-button"
        :icon-name="isProductFavorite(currentProduct?.id) ? 'favorite' : 'favorite-outline-gray'"
        @click="toggleProductFavorite(currentProduct?.id)"
      />
      <LazySharePopover
        variant="icon"
        :title="currentProduct?.name?.value"
      />
    </template>
  </HeaderBase>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useFavoritesStore } from '@/stores/favorites'
import { useProductStore } from '@/stores/product'
import { useNavigate } from '@/composables/useNavigate'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'

import HeaderBase from './HeaderBase.vue'

const LazySharePopover = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/organisms/ProductPageInfo/SharePopover.vue')
)

const { isProductFavorite, toggleProductFavorite } = useFavoritesStore()
const { currentProduct } = useProductStore()
const { getUrl } = useNavigate()
const router = useRouter()

const goBack = () => {
  const hasBackHistory = !!router.options.history.state?.back

  if (hasBackHistory) {
    router.back()
  } else {
    getUrl(currentProduct.value?.category ?? '')
  }
}
</script>
